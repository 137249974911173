<template>
  <div class="card" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Invoice No</th>
          <th>Invoice Date</th>
          <th>Patient Details</th>
          <th>Consultant</th>
          <th>Admission No</th>
          <th>Word/Bed/Cabin</th>
          <th>Unit</th>
          <th class="text-right">Bill Amount</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in salesList" :key="i">
          <td> {{ item.bill_number }}</td>
          <td> {{ item.date }}</td>
          <td style="width: 15%">
            <p style="font-weight: bold">{{ item.party_name }}</p>
            <p>{{ getPartyInfo(item.contact, 'mobile_no') }}</p>
          </td>
          <td>
            {{ item.ipd_register && item.ipd_register.human_resource && item.ipd_register.human_resource.name }}
          </td>
          <td> {{ item.ipd_register && item.ipd_register.admission_no }}</td>
          <td>
            {{ item.ipd_register && item.ipd_register.service_resource && item.ipd_register.service_resource.name }}
          </td>
          <td> {{ item.business && item.business.name }}</td>
          <td class="text-right"> {{ item.bill_amount }}</td>
          <td> {{ item.status }}</td>
          <td>
            <div class="d-flex">
              <button class="btn btn-secondary btn-sm"
                      @click="goToPrint(item.id, item.sale_type === 'opd_invoice')"
                      style="cursor: pointer; margin-left: 10px"
              >
                <i class="fas fa-print"></i>
              </button>
              <button class="btn btn-danger btn-sm p-0"
                @click="goToReturnPage(item.id, item.sale_type)"
                style="cursor: pointer; margin-left: 10px"
                v-if="item.status === 'active' && item.can_return_investigation_invoice !== 0 && !item.incentive_sale_master_id"
              >
                <i class="far fa-window-close" style="font-size: 1.3em; margin: 5px 10px"></i>
              </button>
              <button class="btn btn-danger btn-sm"
                @click="goToPartialReturn(item.id, item.sale_type)"
                style="cursor: pointer; margin-left: 10px"
                v-if="shouldShowPartialReturn(item) && !item.incentive_sale_master_id"
              >
                <i class="fas fa-undo-alt"></i>
              </button>
              <button v-if="item.sale_return_masters.length > 0"
                      class="btn btn-primary btn-sm"
                      @click="goToPrintReturn(item.sale_return_masters)"
                      style="cursor: pointer; margin-left: 10px"
              >
                <i class="fas fa-print"></i>
              </button>
            </div>
          </td>
        </tr>

        </tbody>
      </table>
      <p v-if="!salesList.length" class="text-center mt-5">No data</p>
    </div>
    <SaleReturnPrint
        v-if="store.state.isModalOpenTwo"
        :saleReturnData="saleReturnData"
        :companyInfo="companyInfo"
        :billType="billType"
    />
  </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter';
import handlePurchase from "@/services/modules/purchase";
import pdfPrinter from '@/services/utils/pdfPrinter';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import {useStore} from "vuex";
import hospitalPdfPrinterHelper from "@/services/utils/hospitalPdfPrinterHelper";
import SaleReturnPrint from '@/components/molecule/company/hospital/SaleReturnPrint';

export default {
  name: 'HospitalListTable',
  components: {
    SaleReturnPrint
  },
  data() {
    return {
      companyInfo: {},
      invoice: {},
      barcodeQuery: '',
      barcode: '',
      user: {},
      invoiceRes: {},
      qrcode: '',
      saleReturnData: []
    }
  },
  props: {
    salesList: Array,
    billTypeList: Array,
    redirect_route: {
        type: String,
        default: "bill-manager"
    },
    billType: {
      type: String
    }
  },
  setup() {
    const {commaFormat} = figureFormatter()
    const {fetchSalesBill} = handlePurchase();
    const {fetchCompanyInfo} = handleCompany();
    const {exportToPDF} = pdfPrinter();
    const {fetchBarcode} = handleBarcode();
    const store = useStore();
    const {generatePdf} = hospitalPdfPrinterHelper();
    const shouldShowPartialReturn = (item) => {
        return item.status !== 'returned' && ['pharmacy_invoice', 'investigation_invoice','general_invoice'].includes(item.sale_type)
    }

    return {
      commaFormat,
      fetchSalesBill,
      exportToPDF,
      fetchCompanyInfo,
      fetchBarcode,
      generatePdf,
      shouldShowPartialReturn,
      store
    }
  },
  mounted() {
    this.fetchCompanyInfo(this.$route.params.companyId).then((res) => {
      this.companyInfo = res.data;
    }).catch((error) => {
      console.log(error);
    });

    this.user = this.store.state.user.user
  },
  methods: {
    goToPrint(id, isOpd = false) {
      const companyId = this.$route.params.companyId
      const companyQuery = `?company_id=${companyId}`;

      this.fetchSalesBill(id, companyQuery).then((res) => {
        if (res) {
          this.invoiceRes = res.data;
          if (isOpd && this.invoiceRes.service_general && this.invoiceRes.service_general.service_resource) {
            this.invoiceRes.consultant = this.invoiceRes.service_general.service_resource.name ?? ''
          }
        }
      }).then(() => {
        this.generatePdf(this.companyInfo, this.invoiceRes, 'BILL', true, true, this.invoiceRes.invoice_type === "OPD Bill")
      }).catch((err) => {
        console.log(err);
      })
    },
    goToReturnPage(id, saleType) {
      let params = {
        companyId: this.$route.params.companyId,
        moduleId: this.$route.params.moduleId,
        menuId: this.$route.params.menuId,
        pageId: this.$route.params.pageId,
        invoiceId: id
      }
      let routeName = null;
      switch (saleType) {
        case "opd_invoice":
          routeName = "opd-bill-return"
          break;
        case "pharmacy_invoice":
          routeName = "pharmacy-bill-return"
          break;
        case "hospital_invoice":
          routeName = "hospital-bill-return"
          break;
        case "investigation_invoice":
          routeName = "investigation-bill-return"
          break;
        case "ipd_invoice":
          routeName = "ipd-bill-return"
          break;
       case "general_invoice":
          routeName = "general-bill-return"
          break;
        default:
          routeName = null
          break;
      }
      if (routeName !== null) {
        this.$router.push({
          name: routeName,
          params,
          query: {...this.$route.query, redirect_route: this.redirect_route}
        })
      }
    },
    goToPartialReturn(id, invoiceType) {
      let params = {
        companyId: this.$route.params.companyId,
        moduleId: this.$route.params.moduleId,
        menuId: this.$route.params.menuId,
        pageId: this.$route.params.pageId,
        invoiceId: id
      }

      const routeNames = {
        pharmacy_invoice: "pharmacy-bill-partial-return",
        investigation_invoice: 'investigation-partial-bill-return',
        general_invoice: 'general-bill-partial-return'
      };

      this.$router.push({
        name: routeNames[invoiceType] ?? "pharmacy-bill-partial-return",
        params,
        query: {...this.$route.query, redirect_route: this.redirect_route}
      })
    },
    formatDate(date) {
      const formattedDate = date.toISOString().split('T')[0];
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;
      const formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;

      return this.formatToDdMmYy(formattedDate) + ' ' + formattedTime
    },
    formatToDdMmYy(date) {
      const dat = date.split('-');
      return `${dat[2]}-${dat[1]}-${dat[0]}`;
    },
    getPartyInfo(contact, fieldName) {
      if (contact && contact.profile_items) {
        const field = contact.profile_items.find(item => item.field === fieldName)
        return field && field.value
      }
      return ""
    },
    goToPrintReturn(data) {
      this.saleReturnData = data;
      this.store.state.isModalOpenTwo = true;
    }
  }
}
</script>
